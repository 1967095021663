import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import i1 from './img/Invest/1.jpg';
import i2 from './img/Invest/2.jpg';
import i3 from './img/Invest/3.jpg';
import i4 from './img/Invest/4.jpg';

const images = [
    {
        original: i1,
        thumbnail: i1,
        thumbnailHeight: 69,
    },
    {
        original: i2,
        thumbnail: i2,
        thumbnailHeight: 69,
    },
    {
        original: i3,
        thumbnail: i3,
        thumbnailHeight: 69,
    },
    {
        original: i4,
        thumbnail: i4,
        thumbnailHeight: 69,
    }
];

export default class Invest extends React.Component<{}> {
    public render() {
        return (<div className={'renovationContent'}>
            <div className={'renovationText'}>
                <h1>Invest Development</h1>
                <p></p>
                <p></p>
            </div>
            <ImageGallery items={images} lazyLoad={true} thumbnailPosition={'left'} showThumbnails={false} showFullscreenButton={true} showPlayButton={false} />
        </div>);
    }
}