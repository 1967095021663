import React from 'react';
import './App.scss';
import { ReactComponent as InstLogo } from './img/inst.svg';
import { ReactComponent as ContactUs } from './img/contact.svg';
import Logo from './img/logo_horizon_white.png';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './Home';
import Renovation from './Renovation';
import Invest from './Invest';
import Development from './Development';
import Vacancies from './Vacancies';
import Contact from './ContactUs';


export enum Lang {
  en,
  pl,
  ru
};

export interface IAppProps {

};

export interface IAppState {
  lang: Lang;
}

export default class App extends React.Component<IAppProps, IAppState> {
  public constructor(props: IAppProps) {
    super(props);
    this.state = { lang: Lang.en };
  }

  public render(): React.ReactElement<{}> {
    return (
      <div className={'stankorApp'}>
        <header className={'header'}>
          <div className={'headerContainer'}>
            <div className={'decorationClear'}>
              <div className={'decorationBorder'}></div>
            </div>
            <div className={'logoContainer'}>
              <a href={'/'}>
                <img src={Logo} alt='Stankor Logo' className={'logo'} />
              </a>
            </div>
            <div className={'navContainer'}>
              <nav className={'menu'}>
                <ul>
                  <li><a rel="nofollow" href={'/contact'}><ContactUs className={'icon'} /></a></li>
                  <li><a rel="nofollow" href={'/'}><InstLogo className={'icon'} /></a></li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
        <article className={'bodyContainer'}>
          <Router>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/renovation">
                <Renovation />
              </Route>
              <Route path="/invest">
                <Invest />
              </Route>
              <Route path="/develop">
                <Development />
              </Route>
              <Route path="/vacancies">
                <Vacancies />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
            </Switch>
          </Router>
        </article>
        {/* <footer className={'footer'}>
          Stankor @ 2021
        </footer> */}
      </div>
    );
  }

  // private switchToRenovation = (event: any) => {
  //   event.preventDefault();
  //   this.setState({ ...this.state, mode: Mode.renovation });
  //   console.log('123');
  // }

}
