import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";

export default class Vacancies extends React.Component<{}> {
    public render() {
        return (<div className={'contentCols'}>
            <div className={'contentText'}>
                <div className={'content'}>
                    <h1>Stankor is looking for you!</h1>
                    <p>
                        We are hiring the following proffessionals:
                        <ul>
                            <li>Painters</li>
                            <li>Tilers</li>
                            <li>Helpers</li>
                        </ul>
                        Work experience is highly appreciated.
                    </p>
                    <p>We offers highly revarded work, work experience and friendly stuff in a complex projects around the Europe.</p>
                    <p>
                        To get more information, please, contact us directly or using <a href="/contact">Contact Us</a> form.
                    </p>
                </div>
            </div>
            <div className={'contentCube'}>
            </div>
        </div>);
    }
}

