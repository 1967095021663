import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import i1 from './img/renovation/1.jpg';
import i2 from './img/renovation/2.jpg';
import i3 from './img/renovation/3.jpg';
import i4 from './img/renovation/4.jpg';
import i6 from './img/renovation/5.jpg';
import i7 from './img/renovation/6.jpg';
import i8 from './img/renovation/7.jpg';
import i9 from './img/renovation/8.jpg';
import i10 from './img/renovation/9.jpg';
import i11 from './img/renovation/10.jpg';
import i12 from './img/renovation/11.jpg';
import i13 from './img/renovation/12.jpg';
import i14 from './img/renovation/13.jpg';
import i15 from './img/renovation/14.jpg';
import i16 from './img/renovation/15.jpg';
import i17 from './img/renovation/16.jpg';
import i18 from './img/renovation/17.jpg';
import i19 from './img/renovation/18.jpg';
import i20 from './img/renovation/19.jpg';
import i21 from './img/renovation/20.jpg';
import i22 from './img/renovation/21.jpg';
import i23 from './img/renovation/22.jpg';
import i24 from './img/renovation/23.jpg';
import i25 from './img/renovation/24.jpg';
import i26 from './img/renovation/24.jpg';
import i27 from './img/renovation/26.jpg';
import i28 from './img/renovation/27.jpg';
import i29 from './img/renovation/28.jpg';
import i30 from './img/renovation/29.jpg';




const images = [
    {
        original: i1,
        thumbnail: i1,
        thumbnailHeight: 69,
        imageHeight: 900,
        imageWidth: 1000
    },
    {
        original: i2,
        thumbnail: i2,
        thumbnailHeight: 69,
    }, 
    {
        original: i3,
        thumbnail: i3,
        thumbnailHeight: 69,
    }, 
    {
        original: i4,
        thumbnail: i4,
        thumbnailHeight: 69,
    }, 
    {
        original: i6,
        thumbnail: i6,
        thumbnailHeight: 69,
    },
    {
        original: i7,
        thumbnail: i7,
        thumbnailHeight: 69,
    },
    {
        original: i8,
        thumbnail: i8,
        thumbnailHeight: 69,
    },
    {
        original: i9,
        thumbnail: i9,
        thumbnailHeight: 69,
    },
    {
        original: i10,
        thumbnail: i10,
        thumbnailHeight: 69,
    },
    {
        original: i11,
        thumbnail: i11,
        thumbnailHeight: 69,
    },
    {
        original: i12,
        thumbnail: i12,
        thumbnailHeight: 69,
    },
    {
        original: i13,
        thumbnail: i13,
        thumbnailHeight: 69,
    },
    {
        original: i14,
        thumbnail: i14,
        thumbnailHeight: 69,
    }, 
    {
        original: i15,
        thumbnail: i15,
        thumbnailHeight: 69,
    }, 
    {
        original: i16,
        thumbnail: i16,
        thumbnailHeight: 69,
    }, 
    {
        original: i17,
        thumbnail: i17,
        thumbnailHeight: 69,
    }, 
    {
        original: i18,
        thumbnail: i18,
        thumbnailHeight: 69,
    }, 
    {
        original: i19,
        thumbnail: i19,
        thumbnailHeight: 69,
    },
    {
        original: i20,
        thumbnail: i20,
        thumbnailHeight: 69,
    },
    {
        original: i21,
        thumbnail: i21,
        thumbnailHeight: 69,
    },
    {
        original: i22,
        thumbnail: i22,
        thumbnailHeight: 69,
    },
    {
        original: i23,
        thumbnail: i23,
        thumbnailHeight: 69,
    },
    {
        original: i24,
        thumbnail: i24,
        thumbnailHeight: 69,
    },
    {
        original: i25,
        thumbnail: i25,
        thumbnailHeight: 69,
    },
    {
        original: i26,
        thumbnail: i26,
        thumbnailHeight: 69,
    },
    {
        original: i27,
        thumbnail: i27,
        thumbnailHeight: 69,
    },
    {
        original: i28,
        thumbnail: i28,
        thumbnailHeight: 69,
    },
    {
        original: i29,
        thumbnail: i29,
        thumbnailHeight: 69,
    },
    {
        original: i30,
        thumbnail: i30,
        thumbnailHeight: 69,
    },
    {
        original: i2,
        thumbnail: i2,
        thumbnailHeight: 69,
    }
];

export default class Renovation extends React.Component<{}> {
    public render() {
        return (<div className={'renovationContent'}>
            <div className={'renovationText'}>
                <h1>Stankor renovates</h1>
                <p>We offering complex and comprehensive renovation of restaurants, cafes, shops and individual living dwellings.</p>
                <p>For more then 8 years STANKOR team has renovated 40+ facilities, includding network restaurants and shops, individual dwellings and much more! All our services includes full renovation lifelycle and met all customer requirements and timelines.</p>
            </div>
            <ImageGallery items={images} lazyLoad={true} thumbnailPosition={'left'} showThumbnails={false} showFullscreenButton={true} showPlayButton={false} />
        </div>);
    }
}