import React from 'react';
import renovation from './img/back_renovation.jpg';
import development from './img/back_development.jpeg';
import investors from './img/back_investors.jpeg';
import vacancies from './img/back_vacancies.jpeg';

export default class Home extends React.Component<{}> {
    public render() {
        return (

            <div className={'contentCols'}>
                <div className={'contentText'}>
                    <div className={'content'}>
                        <h1>Your key to smart</h1>
                        <h1>and innovative property developer</h1>
                        <p>
                            Stankor is a fast growing experienced property developer specializing in the realization of modern residential investments and complex renovation of restaurants, cafes, shops and individual living dwellings.
                        </p>
                        <p>
                            For more then 8 years we providing world-class services to our clients and customers. We are proud of our project realizations and delivery standards.
                        </p>
                        <div className={'aboutUs'}>
                            <h3>About us in a few figures</h3>
                            <div className={'numbers'}>
                                <div className={'detail'}>
                                    <h5>
                                        2013
                                    </h5>
                                    <p>founding year</p>
                                </div>
                                <div className={'detail'}>
                                    <h5>
                                        60+
                                    </h5>
                                    <p>successful facilities</p>
                                </div>
                                <div className={'detail'}>
                                    <h5>
                                        25.000+м²
                                    </h5>
                                    <p>developed</p>
                                </div>
                            </div>
                            <div className={'numbers'}>
                                <div className={'detail'}>
                                    <h5>
                                        40+
                                    </h5>
                                    <p>happy partners</p>
                                </div>
                                <div className={'detail'}>
                                    <h5>
                                        1000+м²
                                    </h5>
                                    <p>under development</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'contentCube'}>
                    <div className={'cubeContent'}>
                        <div className="grid">
                            <figure className="effect-dexter" >
                                <img src={renovation} alt="img19" style={{ objectFit: 'fill', objectPosition: '0 -85px' }} />
                                <figcaption>
                                    <h2>Renovation</h2>
                                    <p>We offering complex and comprehensive renovation of restaurants, cafes, shops and individual living dwellings.</p>
                                    <a href="/renovation">View more</a>
                                </figcaption>
                            </figure>

                            <figure className="effect-dexter">
                                <img src={development} alt="img19" style={{ objectFit: 'fill', objectPosition: '0 -85px' }} />
                                <figcaption>
                                    <h2>Facade works</h2>
                                    <p>We offering building of individual living houses, offices and much more.</p>
                                    <a href="/develop">View more</a>
                                </figcaption>
                            </figure>

                            <figure className="effect-dexter">
                                <img src={investors} alt="img19" style={{ objectFit: 'fill', objectPosition: '0 -85px' }} />
                                <figcaption>
                                    <h2>For Investors</h2>
                                    <p>Invest in our future projects.</p>
                                    <a href="/invest">View more</a>
                                </figcaption>
                            </figure>

                            <figure className="effect-dexter">
                                <img src={vacancies} alt="img19" style={{ objectFit: 'fill', objectPosition: '0 -85px' }} />
                                <figcaption>
                                    <h2>Vacancies</h2>
                                    <p>We looking for talents.</p>
                                    <a href="/vacancies">View more</a>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}