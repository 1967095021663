import { PrimaryButton, TextField } from '@fluentui/react';
import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import{ init, send } from 'emailjs-com';
init("user_jOvmYWy06iWojhTo0xR9I");

export interface IContactState {
    name: string;
    email: string;
    message: string;
}

export interface IContactProps {

}

export default class Contact extends React.Component<IContactProps, IContactState> {

    constructor(props: IContactProps) {
        super(props);
        this.state = { name: '', email: '', message: '' };
    }
    public render() {
        return (<div className={'contentCols'}>
            <div className={'contentText'}>
                <div className={'content'}>
                    <div className={'contactForm'}>
                        <TextField label={"Name"} value={this.state.name} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.handleNameChange(newValue)} />
                        <TextField label={"Email Address"} value={this.state.email} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.handleEmailChange(newValue)} />
                        <TextField label={"Message"} multiline={true} value={this.state.message} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.handleMessageChange(newValue)} />
                        <PrimaryButton text={"Send"} onClick={() => this.handleSubmit()} />
                    </div>
                </div>
            </div>
        </div>);
    }

    private handleNameChange = (newValue?: string) => {
        if (newValue) {
            this.setState({ ...this.state, name: newValue });
        }
    }

    private handleEmailChange = (newValue?: string) => {
        if (newValue) {
            this.setState({ ...this.state, email: newValue });
        }
    }

    private handleMessageChange = (newValue?: string) => {
        if (newValue) {
            this.setState({ ...this.state, message: newValue });
        }
    }

    private handleSubmit = () => {
        send("service_44hi053", "template_5kw82tl", {from_name: `${this.state.name} || ${this.state.email}`, message: this.state.message},
            
            ).then(res => {
              console.log('Email successfully sent!');
              window.location.href = '/';
            })
            // Handle errors here however you like, or use a React error boundary
            .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    }
}

