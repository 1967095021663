import React from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import "./Development.module.scss";
import i1 from './img/develop/1.jpg';
import i2 from './img/develop/2.png';
import i3 from './img/develop/3.jpg';
import i5 from './img/develop/5.png';
import i6 from './img/develop/6.jpg';
import i7 from './img/develop/7.jpg';
import i8 from './img/develop/8.jpg';
import i9 from './img/develop/9.jpg';
import i10 from './img/develop/10.jpg';
import i11 from './img/develop/11.jpg';
import i12 from './img/develop/12.jpg';
import i13 from './img/develop/13.jpg';
import i14 from './img/develop/14.jpg';
import i15 from './img/develop/15.jpg';
import i16 from './img/develop/16.jpg';
import i17 from './img/develop/17.jpg';
import i18 from './img/develop/18.png';


const images: readonly ReactImageGalleryItem[] = [
    {
        original: i1,
        thumbnail: i1,
        thumbnailHeight: 69,
    },
    {
        original: i3,
        thumbnail: i3,
        thumbnailHeight: 69,
    },
    {
        original: i6,
        thumbnail: i6,
        thumbnailHeight: 69,
    },
    {
        original: i7,
        thumbnail: i7,
        thumbnailHeight: 69,
    },
    {
        original: i8,
        thumbnail: i8,
        thumbnailHeight: 69,
    },
    {
        original: i9,
        thumbnail: i9,
        thumbnailHeight: 69,
    },
    {
        original: i10,
        thumbnail: i10,
        thumbnailHeight: 69,
    },
    {
        original: i11,
        thumbnail: i11,
        thumbnailHeight: 69,
    },
    {
        original: i12,
        thumbnail: i12,
        thumbnailHeight: 69,
    },
    {
        original: i13,
        thumbnail: i13,
        thumbnailHeight: 69,
    },
    {
        original: i14,
        thumbnail: i14,
        thumbnailHeight: 69,
    },
    {
        original: i5,
        thumbnail: i5,
        thumbnailHeight: 69,
    },{
        original: i15,
        thumbnail: i15,
        thumbnailHeight: 69,
    },{
        original: i16,
        thumbnail: i16,
        thumbnailHeight: 69,
    },{
        original: i17,
        thumbnail: i17,
        thumbnailHeight: 69,
    },
    {
        original: i2,
        thumbnail: i2,
        thumbnailHeight: 69,
        sizes: "width: 100vh"
    },{
        original: i18,
        thumbnail: i18,
        thumbnailHeight: 69,
    },];

export default class Development extends React.Component<{}> {
    public render() {
        return (<div className={'renovationContent'}>
            <div className={'renovationText'}>
                <h1>We Developing</h1>
                <p>The facade is the meeting point of virtually every other building discipline and our building envelope designs integrate with the structure, building services and others. Understanding of the strategic aims of the project in its earliest stages, we can define how the building facade can achieve the best outcome.</p>
                <p>We specialise in facades that are easily assembled and can be procured with minimum impact to cost and risk factors.</p>
            </div>
            <ImageGallery items={images} lazyLoad={true} thumbnailPosition={'left'} showThumbnails={false} showFullscreenButton={true} showPlayButton={false} />
        </div>)
    }
}

